body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  padding-bottom: 60px; /* Ajusta este valor según la altura de tu Footer */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Añade esto al final del archivo */
::selection {
  background-color: #FFB74D; /* Naranja claro */
  color: #000000; /* Negro para máximo contraste */
}
